<template>
  <el-col :span="24" class="item" >
   <div class="imgWrap"  @click="jumpDetail(info.id)">
        <img v-if="info.quantity<Number(info.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" />
        <img
          :alt="info.name | defaultName"
          :src="info.pic?info.pic + picSuffix : defaultImage"
        />
      </div>
      <h3 class="shopName" :title="info.name">
        {{ info.name | defaultName }}
      </h3>
       <!-- <span class="AddBtn"  @click="jumpDetail(info.id)">兑换 </span> -->
      <div class="priceBox">

        <!-- 666666666 info.payType   1:类型  积分     2  ：其他 -->
        <span class="price" >
          <!-- {{info.comboPrice  | capitalize}} -->
          {{info.points}}积分<span v-if="Number(info.money)>0 &&info.payType!== 1  ">+{{info.money | capitalize}}</span>
        </span>
        <!-- <del class="delPrice">
           {{ info.payType === 1 ? info.points: (info.money  | capitalize)}}
          </del> -->
      </div>
  </el-col>
</template>

<style lang="less" scoped>
@import "~style/index.less";

.item {
  width: 228px;
  background: #fff;
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  padding-left:8px;
  padding-right:8px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
   .AddBtn{
      position: absolute;
      bottom:12px;
      right:10px;
      width: 80px;
      text-align: center;
      padding:5px 0;
      font-size: 14px;
      color: #000;
      background: #FFD303;
      
    }
  &:hover{
   box-shadow: 10px 6px 6px -4px #dedada;
		border-radius:7px;
  }

  .imgWrap {
    padding-top: 15px;
    overflow: hidden;
    position: relative;
    .soldOut{
       display: block;
        width:212px;
      height:212px;
       z-index: 2;
       position:absolute;
       top:10px;
       left:0px;
    }
    img {
      // display: inline-block;
      // width: 100%;
      // height: 150px;
      display: block;
      width: 212px;
      height:212px;
      margin: 0 auto;
    }
  }
  .shopName {
    margin-top:10px;
    height: 22px;
    color: #333;
    font-size:15px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span{
      font-weight: normal;
       font-size:13px;
    }
  }
   .priceBox {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    .price{
        color: #f43c38;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:10px;
        color: #929598;
        font-size: 12px;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        text-indent: 3px;
        line-height: 20px;
    }
  .specification {
    height:20px;
    line-height:20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
      color: #999;
    
  }
  .validity {
    line-height:20px;
     font-size: 12px;
     color: #999;
  }
  .hidePrice {
    color: #f43c38;
    line-height:26px;
    padding-top:6px;
    .price {
      color: #f43c38;
      font-size:18px;
      line-height:26px;
          font-weight: bold;
    }
     .bottomBox{
       float: right;
       font-size: 12px;
       color: #ff3333;
    }
  }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "CommodityZone",
  data() {
    return {
      picSuffix:localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      num:0,
      defaultImage:defaultImage,
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  methods: {
   jumpDetail(id) {
      this.$router.push({
         name: "IntegralMallDetail",
         query: { id: id},
      });
    },
  },
  computed: {

  },

  created() {
    console.log(this.info)
  },
  updated() {
  },
};
</script>
